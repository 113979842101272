html {
  min-height: 100%;
}

body {
  background-color: #fefefe;
  background-image: url("../images/shared/background.jpg");
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
}

#main-container {
  margin-top: 52px;
}

.panel {
  margin: auto;
  max-width: 520px;
  box-shadow: #375154 0px 3px 6px 0px;
}

/*# sourceMappingURL=empty.css.map */
